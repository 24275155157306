import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ICalendar } from 'datebook';

import logo from './images/logo_cueva.png';

const ReservaExitosa = () => {
    const navigate = useNavigate();

    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('payment_id');

    // Estados
    const [user, setUser] = useState(JSON.parse(window.sessionStorage.getItem('userdata')));
    const [userres, setUserres] = useState(JSON.parse(window.sessionStorage.getItem('reservations')));
    const [res, setRes] = useState(JSON.parse(window.sessionStorage.getItem('reservation')));
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    console.log(userres);
    console.log(user);
    console.log(res);

    // Validar el pago con la base de datos
    const validatePayment = async () => {
        try {
            const response = await axios.post('https://osocard.renderplayz.com/server/validar_pago.php', {
                payment_id: paymentId
            });

            if (response.data.valid) { // Ahora solo verificamos si "valid" es true
                setIsVerified(true);
                updateICS(res);
            } else {
                setErrorMessage("El pago aún no ha sido verificado en la base de datos.");
            }
        } catch (error) {
            setErrorMessage("Error al validar el pago.");
            console.error("Error al validar el pago:", error);
        } finally {
            setLoading(false);
        }
    };


    // Añadir nuevo evento al ICS
    function updateICS(event) {
        const icalEvent = new ICalendar({
            title: event.title,
            location: event.location,
            description: event.description,
            start: new Date(event.start),
            end: new Date(event.end),
            stamp: new Date(event.stamp),
        });

        const icsContent = icalEvent.render();
        const req = {
            icsContent: icsContent.toString(),
            id: res.uid,
            stat: 'Pagada',
            extraParam: res.title.toLowerCase().replace(/\s+/g, '') // Parámetro adicional si es necesario
        };

        console.log(req);

        axios.post('https://osocard.renderplayz.com/server/date_edit.php', req)
            .then(response => {
                console.log('Evento añadido al archivo ICS en el servidor:', response.data);
                sendMailRes();
                addReserv();
            })
            .catch(error => {
                console.error('Error al añadir el evento al archivo ICS en el servidor:', error);
            });
    }

    // Añadir reserva al usuario
    async function addReserv() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: paymentId,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: parseInt(user[0].reservas) + 1,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' + error);
        }
    }

    // Enviar correo de confirmación de reserva
    async function sendMailRes() {
        try {
            const formData = new FormData();

            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('correo', user[0].correo);
            formData.append('asunto', 'Reservación pagada');
            formData.append('fecha_init', res.start);
            formData.append('fecha_end', res.end);

            const response = await axios.post('https://osocard.renderplayz.com/server/mail_reserva.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (paymentId) {
            validatePayment();
        } else {
            setLoading(false);
            setErrorMessage("No se encontraron datos de pago.");
        }
    }, [paymentId]);

    return (
        <div className="main">
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo" />
            </div>
            <div className="confirm_box">
                {loading ? (
                    <p>Verificando pago...</p>
                ) : isVerified ? (
                    <div>
                        <h1>PAGO DE RESERVA EXITOSO</h1>
                        <button onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                    </div>
                ) : (
                    <p>{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

export default ReservaExitosa;
