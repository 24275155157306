import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'; // Importa moment desde moment-timezone
import 'moment/locale/es';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ICAL from 'ical.js';
import Top from "./top.js";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { ICalendar } from 'datebook';

//CARRUSEL DE IMÁGENES
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import im1 from "./images/oso_negro/oso_negro1.webp";
import im2 from "./images/oso_negro/oso_negro2.webp";
import im3 from "./images/oso_negro/oso_negro3.webp";
import im4 from "./images/oso_negro/oso_negro4.webp";
import im5 from "./images/oso_negro/oso_negro5.webp";
import im6 from "./images/oso_negro/oso_negro6.webp";
import im7 from "./images/oso_negro/oso_negro7.jpeg";
import im8 from "./images/oso_negro/oso_negro8.webp";
import im9 from "./images/oso_negro/oso_negro9.webp";
import im10 from "./images/oso_negro/oso_negro10.webp";

moment.locale('es'); // Establece el idioma en español
const localizer = momentLocalizer(moment);

//Establecer la zona horaria por defecto
moment.tz.setDefault('UTC');

//MERCADO PAGO
initMercadoPago('APP_USR-5a1deac4-0db5-47ee-ac32-d38d42ac69d1');


const OsoNegro = () =>{

    //USE NAVIGATE
    const navigate = useNavigate();

    //PREF_PAGO
    const [pref, setPref] = useState('');
    const [numdays, setNumdays] = useState(0);

    //VARIABLES DE CABAÑA
    var cabin = "Oso Negro";
    var cabin_price = 7500;
    
    //SET ACTIVE USER
    const [activeuser, setActiveuser] = useState(window.sessionStorage.getItem('logged'));
   
    //OBTENER USUARIO
    const [user, setUser] = useState(JSON.parse(window.sessionStorage.getItem('userdata')));

    //OBTENER CALENDARIO AIRBNB Y OSO CARD
    const [osoevents, setOsoevents] = useState([]);
    const [airevents, setAirevents] = useState([]);

    const getDates = async () => {
        try {
            const osoResponse = await axios.get('https://osocard.renderplayz.com/server/cal_osonegro.php');

            const { response1, response2 } = osoResponse.data;

            const jcalData1 = ICAL.parse(response2);
            const comp1 = new ICAL.Component(jcalData1);
            const vevents1 = comp1.getAllSubcomponents('vevent');
            console.log(vevents1); // Verifica el contenido de vevents
            const parsedEvents1 = vevents1.map((vevent) => ({
                title: vevent.getFirstPropertyValue('summary'),
                description: vevent.getFirstPropertyValue('description'),
                location: vevent.getFirstPropertyValue('location'),
                start: vevent.getFirstPropertyValue('dtstart').toJSDate(),
                end: vevent.getFirstPropertyValue('dtend').toJSDate(),
                stamp: vevent.getFirstPropertyValue('dtstamp').toJSDate(),
            }));
            setOsoevents(parsedEvents1);

            const jcalData2 = ICAL.parse(response1);
            const comp2 = new ICAL.Component(jcalData2);
            const vevents2 = comp2.getAllSubcomponents('vevent');
            console.log(vevents2); // Verifica el contenido de vevents
            const parsedEvents2 = vevents2.map((vevent) => ({
                title: vevent.getFirstPropertyValue('summary'),
                description: vevent.getFirstPropertyValue('description'),
                start: vevent.getFirstPropertyValue('dtstart').toJSDate(),
                end: vevent.getFirstPropertyValue('dtend').toJSDate(),
            }));
            setAirevents(parsedEvents2);

        } catch (error) {
            console.error('Error al cargar los archivos ICS:', error);
        }
    };

    //SELECCONAR FECHAS
    var today = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    const maxDateString = maxDate.toISOString().split('T')[0];
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [event, setEvent] = useState(null);

    //Bloquear avance si es más de un año del calendario
    const [currentDate, setCurrentDate] = useState(today);
    const handleNavigate = (newDate) => {
        if (newDate < today || newDate > maxDate) {
            return; // No permitimos navegar fuera del rango
        }
        setCurrentDate(newDate); // Actualizamos la fecha visible si está en el rango
    };
    
    // Función para calcular las diferencias de fechas y validar la selección
    const selectDate = () => {
        // Convertir las fechas a objetos de fecha
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (!start || !end) {
            alert('Por favor selecciona ambas fechas.');
            return;
        }

        // Convertir las fechas a milisegundos
        const startMs = start.getTime();
        const endMs = end.getTime();

        // Calcular la diferencia en milisegundos
        const differenceMs = endMs - startMs;

        // Convertir la diferencia de milisegundos a días (86400000 milisegundos por día)
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

        if (start < today || end < today) {
            alert("No puedes elegir fechas pasadas");
            return;
        }

        if (start >= end) {
            alert('La fecha de salida debe ser después de la fecha de registro.');
            return;
        }

        // Crear el objeto del evento
        const selectedStart = new Date(start); 
        selectedStart.setUTCHours(12); 

        const selectedEnd = new Date(end); 
        selectedEnd.setUTCHours(12); 
        
        const infoevent = {
            title: cabin,
            start: selectedStart.toISOString(),
            end: selectedEnd.toISOString(),
            description: user[0].nombre + ' ' + user[0].apellido + ' ' + 'ID:' + user[0].id,
            location: "Pendiente",
        };

        console.log(infoevent);

        // Verificar si las fechas seleccionadas ya están ocupadas
        const isEventExist1 = osoevents.some(event => {
            return (
                (start > new Date(event.start) && start < new Date(event.end)) || // start está dentro del rango del evento
                (end > new Date(event.start) && end < new Date(event.end)) || // end está dentro del rango del evento
                (start < new Date(event.start) && end > new Date(event.end)) || // El rango seleccionado cubre completamente el evento
                (end === new Date(event.start)) // end no puede ser igual a event.start
            );
        });

        const isEventExist2 = airevents.some(event => {
            return (
                (start > new Date(event.start) && start < new Date(event.end)) || // start está dentro del rango del evento
                (end > new Date(event.start) && end < new Date(event.end)) || // end está dentro del rango del evento
                (start < new Date(event.start) && end > new Date(event.end)) || // El rango seleccionado cubre completamente el evento
                (end === new Date(event.start)) // end no puede ser igual a event.start
            );
        });

        console.log(isEventExist1);
        console.log(isEventExist2);

        // Si no hay eventos ocupados y la diferencia de días es mayor a 1
        if (!isEventExist1 && !isEventExist2 && differenceDays > 0) {
            setEvent(infoevent);
            document.getElementById('pop').style.display = "flex";
            setNumdays(differenceDays); // Establecer el número de días
            console.log(`Número de días: ${differenceDays}`);
        } else {
            alert('Fechas ocupadas o seleccionaste un solo día.');
        }
    };
    
    //CONFIRMAR RESERVA
    function updateICS(event) {
        // Crear un nuevo objeto ICalendar para el nuevo evento
        const icalEvent = new ICalendar({
            title: event.title,
            location: event.location,
            description: event.description,
            start: new Date(event.start),
            end: new Date(event.end),
            stamp: new Date(event.stamp),
        });

        // Convertir el objeto ICalendar a texto en formato ICS
        const icsContent = icalEvent.render();
        const req = {
            icsContent: icsContent.toString(),
            extraParam: event.title.toLowerCase().replace(/\s+/g, '')// Parámetro adicional si es necesario
        };

        console.log(req);

        // Realizar la solicitud para añadir el evento al archivo ICS en el servidor
        axios.post('https://osocard.renderplayz.com/server/date_add.php', req)
            .then(response => {
                console.log('Evento añadido al archivo ICS en el servidor:', response.data);
                sendMailRes();
                navigate('/perfil');
            })
            .catch(error => {
                console.error('Error al añadir el evento al archivo ICS en el servidor:', error);
            });
    }

    //ENVIAR CORREO
    async function sendMailRes(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('correo',  user[0].correo);
            formData.append('asunto',  'Reservación solicitada');
            formData.append('fecha_init',  event.start);
            formData.append('fecha_end', event.end);
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_reserva.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }   
    }

    //VER RESERVACIÓN AGENDADA
    const [res, setRes] = useState([]);

    function checkDate({start}) {
        const eventsOnDate1 = osoevents.filter(ev => ev.start === start || ev.end === start);
        const eventsOnDate2 = airevents.filter(ev => ev.start === start || ev.end === start);
        const convertedEvents1 = eventsOnDate1.map(event => ({
            ...event,
            start: moment(event.start).format('YYYY-MM-DD'),
            end: moment(event.end).format('YYYY-MM-DD'),            
        }));
        const convertedEvents2 = eventsOnDate2.map(event => ({
            ...event,
            start: moment(event.start).format('YYYY-MM-DD'),
            end: moment(event.end).format('YYYY-MM-DD'),            
        }));
        setRes(convertedEvents1.concat(convertedEvents2));
        document.getElementById('pop2').style.display = "flex";
    }

    //CERRAR POPUP1
    function closePop(){
        var a = document.getElementById('pop');
        a.style.display = 'none';
    }

    //CERRAR POPUP2
    function closePop2(){
        var a = document.getElementById('pop2');
        a.style.display = 'none';
    }


    //USE EFFECT
    useEffect(() => {
        getDates();
    }, []);

   
    //RENDER
    return(

        <div className="main">

            <div className="popup" id="pop">
                <div className="pop_box2">
                    
                    <div className="warn_box">
                        <p>FAVOR DE LEER CON ATENCIÓN</p>
                    </div>
                    <div className="pop_close" onClick={closePop}>&times;</div>
                    <p className="pop_tt">Confirmar fechas de solicitud de reserva</p>
                    <div className="pop_note">
                        <p>Tu solicitud se enviará al anfitrión para ser confirmada</p>
                    </div>
                    <div className="pop_tx">
                        <p><strong>Check-in:</strong> {event != null ? event.start.slice(0, 10) : 'Sin fecha asignada'}</p>
                        <p><strong>Check-out:</strong> {event != null ? event.end.slice(0, 10) : 'Sin fecha asignada'}</p>
                        <div>
                            {user[0].tarjeta === "Gold" && (
                                (user[0].reservas == 2 ? <p><strong>Precio 10% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .10)) * numdays).toLocaleString()}</p> : null) ||
                                (user[0].reservas == 5 ? <p><strong>Precio 15% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .15)) * numdays).toLocaleString()}</p> : null) ||
                                (user[0].reservas == 9 ? <p><strong>Precio 25% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .25)) * numdays).toLocaleString()}</p> : null)
                            )}
                            {user[0].tarjeta === "Black" && (
                                (user[0].reservas == 2 ? <p><strong>Precio 15% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .15)) * numdays).toLocaleString()}</p> : null) ||
                                (user[0].reservas == 5 ? <p><strong>Precio 20% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .20)) * numdays).toLocaleString()}</p> : null) ||
                                (user[0].reservas == 9 ? <p><strong>Precio 30% de descuento x {numdays}:</strong> {((cabin_price - (cabin_price * .30)) * numdays).toLocaleString()}</p> : null)
                            )}
                            {!(user[0].tarjeta === "Gold" || user[0].tarjeta === "Black") || (user[0].visitas != 2 && user[0].visitas != 5 && user[0].visitas != 9) ? 
                                <p><strong>Precio regular x {numdays}:</strong> {(cabin_price * numdays).toLocaleString()}</p> : null}
                        </div>
                    </div>

                    <p className="pop_tt">Términos y condiciones en reservas y precios</p>
                    
                    <div className="pop_list">
                        
                        <div className="pop_block">
                            <h3>Cabaña Oso Negro</h3>
                            <p><strong>Precio regular:</strong> $7,500 por noche.</p>
                            <p className="poplist_min">4 a 6 personas. Incluyendo adultos y niños mayores de 2 años.</p>
                        </div>
                        
                        <div className="pop_block">
                            <p><strong>Entrada:</strong> 2:00 pm (Membears Early Check in: 11:00 am).</p>
                            <p><strong>Salida:</strong> 1:00 pm (Membears Late check out: 2 00 pm).</p>
                            <p className="poplist_min">Penalización de $500 pesos por cada 30 min adicionales.</p>
                        </div>
                        
                        <div className="pop_block">
                            <p className="poplist_bold">Descripción:</p>
                            <ul>
                                <li>1 baño completo. 1 juego toallas (6) para su estancia, shampoo y jabón de manos.</li>
                                <li>Tapanco abierto para 3 personas (colchón individual) con sábanas, almohadas y cobertor en cada una.</li> 
                                <li>1 recámara principal King size con almohadas, Sabanas y cobertor. Con calentador de rayo Infrarrojo y closet.</li> 
                                <li>1 sofá cama matrimonial calentador de rayo infrarrojo.</li> 
                                <li>Parrilla eléctrica de inducción (2 hornillas) con 3 sartenes, 1 olla chica, licuadora, 1 cafetera (no incluye café) y vajilla completa para 6 personas, 1 tostador.</li> 
                                <li>6 copas de vino tinto aluminio.</li> 
                                <li>1 cuchillo para carne.</li>
                                <li>Refrigerador.</li>
                                <li>Tenemos agua de pozo la cuál no es potable (a pesar de estar clorada y filtrada) ya que estamos en el bosque; traer suficiente agua potable.</li> 
                                <li>Terraza con Asador de gas y comedor exterior para 4 personas.</li>
                                <li>Comedor interior para 6 y barra desayunador para 2.</li> 
                                <li>Chimenea (la leña incluida es la proporcionada al entrar, le invitamos a racionarla) costo adicional de $500 pesos por reabastecimiento durante el día).</li>
                                <li>Calentador eléctrico móvil de piso.</li>
                                <li>1 abanico eléctrico.</li>
                                <li>4 sillas en terraza.</li>
                                <li>Servicio de concierge de 8 am a 10:30 pm, posteriormente sólo urgencias.</li> 
                                <li>NO hay restaurante.</li>
                                <li>Radio para comunicación con nuestro personal, se te explicará cómo usarlo.</li>
                            </ul>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Internet:</p>
                            <ul>
                                <li>El internet solo estará solo disponible en las áreas comunes (NO en cabañas) como el lobby, el bar (ABIERTO DE 1 pm a 11 Pm viernes y sábado), el área del golfito, Brincolín, La Cueva shop para souvenirs, Tirolesa, Resbaladero Big bear, Cancha multiuso, cancha de Basketball, cancha de pádel, Juegos y columpios, Fire Pit, Cine bajo la luna (Verano) Área para practicar yoga  y meditación, la granja de Don Manuel, cancha de canicas y el lago del venado.</li>
                            </ul>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Recomendaciones generales:</p>
                            <ul>
                                <li>Llevar cobertores adicionales o sleeping bag y almohada para el sofá cama.</li>
                                <li>Tenemos agua de pozo la cual no es potable (a pesar de estar clorada y filtrada) ya que estamos en el bosque; traer suficiente agua potable.</li> 
                                <li>Asegúrate de traer tus provisiones lo más completas posibles, tenemos la Tienda de Souvenirs La Cueva Shop con provisiones básicas.</li>
                                <li>Recordar estamos en el bosque, no dejar comida sin guardar ya que podría llamar la atención de algunos animales. También no es raro encontrar algunos dentro y fuera de las cabañas. Estamos en la naturaleza.</li>
                                <li>Nuestro complejo tiene una única entrada que tiene nuestro logo, al llegar nuestro personal te estará esperando para indicarte dónde dejar tu vehículo, posterior a esto, tu equipaje se cambiará a nuestros vehículos 4x4 que te llevarán a tu cabaña. Por su seguridad y la de los demás huéspedes, Ningún otro vehículo puede subir a las cabañas.</li> 
                                <li>Si deseas ver los venados la mejor forma de hacerlo es caminando la “vereda del venado”. Los horarios recomendados por tu seguridad para recorrer las veredas son de 6 am a 11:30 am y de 3:30 pm a 6:30 pm.</li>
                                <li>Nuestro complejo está completamente cercado, existen puertas con candado que tienen su contraseña señalizada, te pedimos amablemente que al cruzar estas puertas las cierres (clave del candado 2021), con la finalidad de seguir cuidando nuestros animales y puedas disfrutar de ellos. La cueva del oso tiene luz en su interior, te pedimos amablemente apagar la luz al salir para que otros huéspedes puedan disfrutar también de la cueva.</li>
                            </ul>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Reglas de la cabaña y complejo:</p>
                            <ol>
                                <li>No vape, no se permite fumar y no drogas adentro ni afuera de las cabañas ni en todo el complejo Estamos dentro del bosque) de ser detectado se pedirá amablemente entregarnos el producto y será devuelto a su salida.</li>
                                <li>Prohibido hacer fogatas y el uso el carbón.</li>
                                <li>No mascotas (NO pet friendly).</li>
                                <li>Bocinas con volumen para uso dentro de la cabaña (es ambiente familiar).</li>
                                <li>No calentar bombones en chimenea (tenemos un fire pit).</li>
                                <li>No Visitas.</li>
                                <li>No alimentar a los venados y borregos, así como siempre mantener su distancia.</li>
                                <li>No se permite sacar las vajillas (platos, vasos etc.) fuera de las cabañas.</li>
                                <li>No se permite la entrada a más personas de la capacidad máxima de la cabaña que se rentó. Evitemos la pena de negar la entrada.</li> 
                                <li>No nos hacemos responsables de pertenencias olvidadas y/o extraviadas.</li> 
                                <li>Los niños deberán estar siempre bajo la supervisión de un adulto.</li>
                                <li>Uso de cancha de papel y tirolesa previa reservación (24 horas previas; no se puede usar durante o después de la lluvia) Una vez reservada NO hay cancelación y se tendrá que cubrir el costo mínimo de 3 personas.</li>
                                <li>A tu salida, la cabaña se debe entregar como se recibió a tu llegada.</li> 
                                <li>Costo no incluye propinas.</li> 
                                <li>Por su seguridad y la del bosque, los asadores, chimeneas y calentones serán prendidos por nuestro personal exclusivamente.</li>
                                <li>Cualquier daño a la propiedad, extravío de equipo de la cabaña y daños a los muebles deberá ser cubierto por el huésped.</li>
                            </ol>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Uso de la cancha de pádel:</p>
                            <ul>
                                <li>Bajo previa reservación. Solicitar reservación con tiempo, y considerar que está a sujeto a disponibilidad. El pago se realiza en el lugar.</li>
                                <ul>
                                    <li>De 7 am a 10 am: $850 pesos la hora y media.</li>
                                    <li>10 a 4 pm: $700 pesos la hora y media.</li>
                                    <li className="last_li">4 a 11 pm: $850 pesos la hora y media.</li>
                                </ul>
                                <li>Renta de pala: 50 pesos por la hora y media</li> 
                                <li>Vendemos pelotas de 150 a 200 pesos en la Cueva shop</li>
                                <li>Consideraciones especiales: la cancha no se podrá usar durante y después de la lluvia.</li>
                            </ul>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Reglamento para el uso de la cancha de pádel:</p>
                            <ol>
                                <li>Uso de cancha es con previa reservación.</li>
                                <li>Uso obligatorio de ropa deportiva, equipo y tenis para pádel.</li> 
                                <li>No introducir alimentos ni bebidas.</li> 
                                <li>Es para uso exclusivo para la práctica de pádel.</li>
                                <li>El máximo de jugadores permitido es de 4 dentro del área de juego.</li> 
                                <li>Prohibido el comportamiento antideportivo.</li> 
                            </ol>
                        </div>

                        <div className="pop_block">
                            <p className="poplist_bold">Tirolesa:</p>
                            <ul>
                                <li>Previa reservación.</li>
                                <li>Horario: sábados de 3 pm a 5 pm.</li>
                                <li>Costo por persona: 400 pesos todo el tiempo que quieras usarla en el horario establecido.</li> 
                                <li>Mínimo para reservar: 3 personas.</li>
                                <li>Una vez reservada NO hay cancelación y se tendrá que cubrir el costo mínimo de 3 personas.</li>
                                <li>Ocasión especial: si deseas colocar algún arreglo floral, mensaje, pétalos, botella de tinto para tu ocasión, pregunta por los costos.</li> 
                            </ul>
                        </div>

                        <p className="poplist_bold">Al aceptar, se da por entendido que “He leído los términos y condiciones, he tenido la oportunidad de aclarar mis dudas y estoy de acuerdo”.</p>

                    </div>


                    <div className="pop_btns2">
                        <button onClick={()=> updateICS(event)}>Aceptar</button>
                        <button className="cancel_btn" onClick={closePop}>Cancelar</button>
                    </div>
                    
                </div>
            </div>

            <div className="popup" id="pop2">
                <div className="pop_box">
                    
                    <div className="pop_close" onClick={closePop2}>&times;</div>
                    <p className="pop_tt">Reservación</p>
                    {
                        res.length > 0
                        ?
                        <div className="pop_tx">
                            <p><strong>Cabaña:</strong> {res[0].title ? res[0].title : 'Sin información'}</p>
                            <p><strong>Check-in:</strong> {res[0].start ? res[0].start : 'Sin fecha asignada'}</p>
                            <p><strong>Check-out:</strong> {res[0].end ? new Date(new Date(res[0].end).setDate(new Date(res[0].end).getDate() )).toISOString().slice(0, 10) : 'Sin fecha asignada'}</p>

                        </div>
                        :
                        <div className="pop_tx">
                            <p>Sin registro</p>
                        </div>
                    }
                    
                    <div className="pop_btns">
                        <button className="cancel_btn" onClick={closePop2}>Cerrar</button>
                    </div>
                    
                </div>
            </div>

            
            <Top/>
                
            <div className="top_nav">
                <Link  className="skip_btn" to="/cabanas">&#x2190; Ver todas las cabañas</Link>
            </div>
            
            {
                activeuser != null && user.length > 0
                
                ?
            
                <div className="calendar_box">

                    <div className="calendar_top">
                        <h1>Cabaña {cabin}</h1>
                        <div className="reser_info">
                            <h3>Costo por noche: ${(cabin_price).toLocaleString()} MXN</h3>
                            <p>Máximo 6 huéspedes</p>
                        </div>
                    </div>

                    <div className="carrusel_box">
                        <Carousel showThumbs={false} autoPlay={false} infiniteLoop={false} showStatus={false} dynamicHeight={true}>
                            <div><img src={im1} /></div>
                            <div><img src={im2} /></div>
                            <div><img src={im3} /></div>
                            <div><img src={im4} /></div>
                            <div><img src={im5} /></div>
                            <div><img src={im6} /></div>
                            <div><img src={im7} /></div>
                            <div><img src={im8} /></div>
                            <div><img src={im9} /></div>
                            <div><img src={im10} /></div>
                        </Carousel> 
                    </div>
                    
                    {
                        osoevents.length == 0 || osoevents.length > 0
                        ?
                        <div className="dates_box">
                            <p className="dates_instr">Elige el rango de fechas para tu reservación.</p>
                            
                            <div className="input_boxes">
                                <div className="input_box">
                                    <label>Check-in:</label>
                                    <input 
                                        type="date" 
                                        value={startDate} 
                                        onChange={(e) => setStartDate(e.target.value)} 
                                        min={today.toISOString().split("T")[0]} // Evitar fechas pasadas
                                        max={maxDateString}
                                    />
                                </div>

                                <div className="input_box">
                                    <label>Check-out:</label>
                                    <input 
                                        type="date" 
                                        value={endDate} 
                                        onChange={(e) => setEndDate(e.target.value)} 
                                        min={startDate} // Evitar seleccionar fechas anteriores a la de registro
                                        max={maxDateString}
                                    />
                                </div>
                                <div className="input_box">
                                    <button className="action_btn3" onClick={selectDate}>Confirmar fechas</button>
                                </div>
                            </div>
                            
                            <Calendar
                                localizer={localizer}
                                min={today}
                                max={maxDate}
                                date={currentDate}
                                onNavigate={handleNavigate}
                                events={osoevents.concat(airevents)}
                                startAccessor="start"
                                endAccessor={event => {
                                    const endDate = new Date(event.end);
                                    endDate.setDate(endDate.getDate() + 1); // Añade un día a la fecha final
                                    return endDate;
                                }}
                                views={['month']} // Mostrar solo la vista mensual
                                style={{ height: 500, width: '100%'}}
                                selectable={false} // Permite hacer clic en los espacios vacíos del calendario
                                //onSelectSlot={selectDate} // Manejar el evento onSelectSlot
                                onDoubleClickEvent={checkDate} // Cambia el color del rango seleccionado
                            />
                        </div>
                        :
                        <p>Por cargar...</p>
                    }
                </div>

                :
                
                <div className="id_box">
                    <p className="nosesion_msg">No hay sesión activa</p>
                        <Link to="/login" className="skip_btn">Iniciar sesión</Link>
                </div>
            }
                
            
        </div>   
    )
}

export default OsoNegro;
