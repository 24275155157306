import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Footer = () =>{


    //RENDER
    return(

        <div className="footer">
            <p>&#169; 2025 La Cueva del Oso</p>
            <Link className="skip_btn" to="/politica">Consulta la Política de Privacidad</Link>
        </div>   
    )
}

export default Footer;
