import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from './images/logo_cueva.png';

const PagoExitoso = () => {
    const navigate = useNavigate();

    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get('payment_id');

    // Estados
    const [user, setUser] = useState(JSON.parse(window.sessionStorage.getItem('userdata')));
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // Validar el pago con la base de datos
    const validatePayment = async () => {
        try {
            const response = await axios.post('https://osocard.renderplayz.com/server/validar_pago.php', {
                payment_id: paymentId
            });

            if (response.data.valid) { // Ahora solo verificamos si "valid" es true
                setIsVerified(true);
                updateUserData();
            } else {
                setErrorMessage("El pago no se encuentra en la base de datos.");
            }
        } catch (error) {
            setErrorMessage("Error al validar el pago.");
            console.error("Error al validar el pago:", error);
        } finally {
            setLoading(false);
        }
    };

    // Solo actualiza el usuario si el pago fue confirmado
    const updateUserData = async () => {
        try {
            const today = new Date();
            const expirationDate = new Date(today);
            expirationDate.setFullYear(today.getFullYear() + 1);

            await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: paymentId,
                active: 'approved',
                tarjeta:  window.sessionStorage.getItem('usercard'),
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: today.toISOString().split('T')[0], // YYYY-MM-DD
                expiration_fecha: expirationDate.toISOString().split('T')[0], // YYYY-MM-DD
                idfront: user[0].idfront,
                idback: user[0].idback
            });

        } catch (error) {
            setErrorMessage("Error al actualizar los datos del usuario.");
            console.error("Error al actualizar los datos del usuario:", error);
        }
    };

    useEffect(() => {
        if (paymentId) {
            validatePayment();
        } else {
            setLoading(false);
            setErrorMessage("No se encontraron datos de pago.");
        }
    }, [paymentId]);

    return (
        <div className="main">
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo" />
            </div>
            <div className="confirm_box">
                {loading ? (
                    <p>Verificando pago...</p>
                ) : isVerified ? (
                    <div>
                        <h1>PAGO EXITOSO</h1>
                        <button onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                    </div>
                ) : (
                    <p>{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

export default PagoExitoso;


